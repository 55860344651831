import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppServices } from "../../../hook/services";
import Loader from "../../../components/Loader"
import Loader2 from "../../../components/Loader2";
import PropTypes from "prop-types";
import Footer from "../../../components/Footer";
const Docs = ({ seleted_language }) => {
  const navigate = useNavigate()
  const AppService = useAppServices()
  const { company_id } = useParams()
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([])
  const [loader, setloader] = useState(true)
  const [docs, setDocs] = useState([]);
  const [profile, setprofile] = useState({});
  const [docsloader, setdocsloader] = useState(true);
  const [activeCategory, setactiveCategory] = useState('')
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleDocsDetails = (id) => {
    navigate("./docs-details/" + id)
  }
  const handleDocsList = (id) => {
    navigate("./docs-list/" + id)
  }
  const getDocsData = async () => {
    setdocsloader(true);
    const { response } = await AppService.docs.get({
      query: `category=${activeCategory}`,
    });
    console.log(response, "responseresponse");
    if (response) {
      setDocs(response.data);
      setdocsloader(false);
    } else {
      setdocsloader(false);
    }
  };
  useEffect(() => {
    getDocsData();
  }, [activeCategory]);
  const getData = async () => {
    // const domain = window.location.hostname
    const domain = 'test.levelupmarketplace.io'
    const { response } = await AppService.docs_category.filter({
      query: `domain=${domain}`
    });
    console.log(response, 'responseresponse')
    if (response) {
      setData(response.data)
      setprofile(response.profile)
      const temp_category = response.data?.category?.filter((item) => !response.data?.snapshot_data?.some((snapshot_data) => item._id == snapshot_data.data_id))
      setactiveCategory(temp_category[0]?._id)
      setloader(false)
    } else {
      setloader(false)
    }
  }
  const onLoad = () => {
    getData()
  }
  useEffect(() => {
    onLoad()
  }, []);

  return (
    <div >
      {
        loader ?
          <Loader />
          :
          <div className="row">
            <div className={`col-lg-3 doc_mobile_menu display_none ${isOpen ? "open" : ""}`}>
              <div>
                <aside className="doc_left_sidebarlist">
                  <div className="open_icon" id="left" onClick={toggleSidebar}>
                    <i className={`arrow_carrot-${isOpen ? "left" : "right"}`}></i>
                  </div>
                  <div className="scroll">
                    <ul className="list-unstyled nav-sidebar">
                      {
                        data?.category?.map((category) =>
                          data?.snapshot_data?.some((snapshot_data) => category._id === snapshot_data.data_id)
                            ?
                            <></>
                            :
                            <li className="nav-item" key={category._id}>
                              <a className="nav-link" style={{ color: category._id == activeCategory ? "blue" : "inherit" }} onClick={() => setactiveCategory(category._id)}>
                                <img src="img/side-nav/home.png" alt="" />
                                {seleted_language == "english" ? category.name : category?.languages?.filter((language) => language.languageId == seleted_language)[0]?.name}
                              </a>
                            </li>

                        )
                      }
                    </ul>
                  </div>
                </aside>
              </div>
            </div>
            {/* Content */}

            <div className="col-xl-9 doc-middle-content ">
              <article className="shortcode_info">
                <div className="doc_categories_guide_area sec_pad">
                  {
                    docsloader ?
                      <Loader2 />
                      :
                      <div className="row">
                        {
                          data?.sub_category?.filter((item) => item.category == activeCategory)?.map((sub_category) =>
                            data?.snapshot_data?.some((snapshot_data) => sub_category._id === snapshot_data.data_id)
                              ?
                              <></>
                              :
                              <div className="col-lg-4 col-sm-6" key={sub_category._id}>
                                <div className="categories_guide_item wow fadeInUp" style={{ minHeight: "445px" }}>
                                  <div className="doc_tag_title">
                                    <h4>  {seleted_language == "english" ? sub_category.name : sub_category?.languages?.filter((language) => language.languageId == seleted_language)[0]?.name}</h4>
                                  </div>
                                  <ul className="list-unstyled tag_list">
                                    {
                                      docs?.filter((item) => item.sub_category == sub_category._id && item.category == activeCategory && !data?.snapshot_data?.some((snapshot_data) => item._id == snapshot_data.data_id))?.map((docs, index) =>
                                        index <= 3 ?
                                          <li key={docs._id}>
                                            <a onClick={() => handleDocsDetails(docs._id)}>
                                              <i className="icon_document_alt"></i> {seleted_language == "english" ? docs.title : docs?.languages?.filter((language) => language.languageId == seleted_language)[0]?.title}
                                            </a>
                                          </li>
                                          :
                                          <></>
                                      )}
                                  </ul>
                                  {docs?.filter((item) => item.sub_category == sub_category._id && item.category == activeCategory)?.length > 4
                                    ?
                                    <a className="doc_border_btn" onClick={() => handleDocsList(sub_category._id)}>
                                      More ({docs?.filter((item) => item.sub_category == sub_category._id && item.category == activeCategory)?.length - 4})<i className="arrow_right"></i>
                                    </a>
                                    :
                                    <></>
                                  }
                                </div>
                              </div>
                          )
                        }
                      </div>
                  }

                </div>
              </article>
              <Footer data={profile} />
            </div>
          </div >
      }
    </div >
  );
};

export default Docs;
Docs.propTypes = {
  seleted_language: PropTypes.string.isRequired,
};