import React, { useEffect, useState } from "react";
import Banner from "../../../components/Banner";
import Navbar from "../../../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { useAppServices } from "../../../hook/services";
import Loader from "../../../components/Loader";

const DocsList = () => {
  const navigate = useNavigate()
  const AppService = useAppServices()
  const { company_id, sub_category_id } = useParams()
  const [data, setData] = useState([])
  const [loader, setloader] = useState(true)
  const [seleted_language, setseleted_language] = useState('english')
  const getDocsData = async () => {
    const { response } = await AppService.docs.get({
      query: `sub_category=${sub_category_id}`
    });
    console.log(response, 'responseresponse')
    if (response) {
      setData(response.data)
      setloader(false)
    } else {
      setloader(false)
    }
  }
  const onLoad = () => {
    getDocsData()
  }
  useEffect(() => {
    onLoad()
  }, []);
  const handleDocsView = (id) => {
    navigate(`/docs/docs-details/${id}`)
  }
  return (
    <>
      <Navbar handleLanguage={(e) => setseleted_language(e.target.value)} />
      <Banner />
      {
        loader ?
          <Loader />
          :
          <div className="container">
            <div className="forum_topic_list_inner">
              <div className="topics-filter d-xl-none">
                <ul className="nav">
                  <li className="nav-item">
                    <a className="nav-link active" >
                      <ion-icon name="earth-outline" /> View all
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">
                      <ion-icon name="swap-horizontal-outline" /> General
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">
                      <ion-icon name="bulb-outline" /> Ideas
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">
                      <ion-icon name="bulb-outline" /> User Feedback
                    </a>
                  </li>
                </ul>
              </div>
              <div className="forum_l_inner">
                <div className="forum_head d-flex justify-content-between">
                  <ul className="nav left">
                    <li>
                      <i className="icon_error-circle_alt" /> 15 Open
                    </li>
                    <li>
                      <a >
                        <i className=" icon_check" /> 202 Closed
                      </a>
                    </li>
                    <li>
                      <a >
                        <i className="icon_refresh" /> Reset
                      </a>
                    </li>
                  </ul>
                  <ul className="nav right">
                    <li>
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Author <i className="arrow_carrot-down" />
                        </button>
                        <div className="dropdown-menu">
                          <h3 className="title">Filter by author</h3>
                          <form action="#" className="cate-search-form">
                            <input type="text" placeholder="Search users" />
                          </form>
                          <div className="all_users scroll">
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-1.png" alt="" /> Donny
                              Peters
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-2.png" alt="" />
                              Linh Knapp
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-3.png" alt="" />
                              Albert Roach
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-4.png" alt="" />
                              Kristin Larsen
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-5.png" alt="" />
                              Ernest Patton
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-2.png" alt="" />
                              Linh Knapp Patton
                            </a>
                            <a className="dropdown-item" >
                              <img src="img/forum/filter-user-1.png" alt="" />
                              Donny Peters
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown right_dir">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Label <i className="arrow_carrot-down" />
                        </button>
                        <div className="dropdown-menu">
                          <h3 className="title">Filter by label</h3>
                          <form action="#" className="cate-search-form">
                            <input type="text" placeholder="Search Users" />
                          </form>
                          <div className="all_users scroll">
                            <a className="dropdown-item" >
                              <span className="color red" />
                              bugs
                            </a>
                            <a className="dropdown-item" >
                              <span className="color blue" />
                              request/feature
                            </a>
                            <a className="dropdown-item" >
                              <span className="color dark_blue" /> Developer Edition
                            </a>
                            <a className="dropdown-item" >
                              <span className="color green" />
                              help wanted
                            </a>
                            <a className="dropdown-item" >
                              <span className="color pink" />
                              question
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown right_dir">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Sort <i className="arrow_carrot-down" />
                        </button>
                        <div className="dropdown-menu">
                          <h3 className="title">Filter by author</h3>
                          <form action="#" className="cate-search-form">
                            <input type="text" placeholder="Search users" />
                          </form>
                          <div className="all_users short-by scroll">
                            <a className="dropdown-item active-short" >
                              <ion-icon name="checkmark-outline" /> Newest
                            </a>
                            <a className="dropdown-item" >
                              {" "}
                              Oldest{" "}
                            </a>
                            <a className="dropdown-item" >
                              {" "}
                              Most commented{" "}
                            </a>
                            <a className="dropdown-item" >
                              {" "}
                              Least commented{" "}
                            </a>
                            <a className="dropdown-item" >
                              {" "}
                              Recently updated{" "}
                            </a>
                            <a className="dropdown-item" >
                              {" "}
                              Recently updated{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="forum_body">
                  <ul className="navbar-nav topic_list">
                    {
                      data?.map((doc) =>
                        <li key={doc._id}>
                          <div className="media">
                            <div className="d-flex">
                              <img
                                className="rounded-circle"
                                src="img/forum/f-user-1.png"
                                alt=""
                              />
                            </div>
                            <div className="media-body">
                              <div className="t_title">
                                <a onClick={() => handleDocsView(doc._id)}>
                                  <h4>{seleted_language == "english" ? doc.title : doc?.languages?.filter((language) => language.languageId == seleted_language)[0]?.title}</h4>
                                </a>
                              </div>
                              <a >
                                <h6>
                                  <img src="img/svg/hashtag.svg" alt="" /> General
                                </h6>
                              </a>
                              <h6>
                                <i className="icon_clock_alt" /> 26 minutes ago
                              </h6>
                            </div>
                            <div className="media-right">
                              <ul className="nav">
                                <li className="dropdown">
                                  <a
                                    className="dropdown-toggle"

                                    role="button"
                                    id="dropdownMenuLink"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <img src="img/forum/small-u-1.png" alt="" />
                                  </a>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink"
                                  >
                                    <div className="media">
                                      <div className="d-flex">
                                        <img src="img/forum/user-hover-1.png" alt="" />
                                      </div>
                                      <div className="media-body">
                                        <a >
                                          <h4>Jonah Terry</h4>
                                        </a>
                                        <a className="follow_btn" >
                                          Follow
                                        </a>
                                      </div>
                                    </div>
                                    <div className="row answere_items">
                                      <div className="col-4">
                                        <a >
                                          <h4>Answers</h4>
                                        </a>
                                        <h6>30</h6>
                                      </div>
                                      <div className="col-4">
                                        <a >
                                          <h4>Question</h4>
                                        </a>
                                        <h6>40</h6>
                                      </div>
                                      <div className="col-4">
                                        <a >
                                          <h4>Followers</h4>
                                        </a>
                                        <h6>30</h6>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <a className="count" >
                                <ion-icon name="heart-outline" /> 5
                              </a>
                              <a className="count" >
                                <ion-icon name="chatbubbles-outline" /> 70
                              </a>
                              <a className="count" >
                                <ion-icon name="eye-outline" /> 420
                              </a>
                            </div>
                          </div>
                        </li>
                      )
                    }
                  </ul>
                </div>
              </div>
              {/* <div className="row pagination_inner">
            <div className="col-lg-2">
              <h6>
                Total: <span> 225 </span>
              </h6>
            </div>
            <div className="col-lg-8">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li className="page-item">
                    <a className="page-link" >
                      <i className="arrow_carrot-left" /> Previous
                    </a>
                  </li>
                  <li className="page-item active">
                    <a className="page-link" >
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" >
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" >
                      3
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" >
                      ...
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" >
                      21
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" >
                      Next <i className="arrow_carrot-right" />
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-2">
              <div className="input-group go_btn">
                <input
                  type="number"
                  className="form-control"
                  aria-label="Recipient's username"
                />
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button">
                    Go
                  </button>
                </div>
              </div>
            </div>
          </div> */}
            </div>
          </div>
      }
    </>
  );
};

export default DocsList;
