import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import 'animate.css/animate.css'
import PropTypes from "prop-types";
import { useAppServices } from '../hook/services';

const Navbar = ({ agency, handleLanguage }) => {
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [isDarkMode, setIsDarkMode] = useState(false);
    const AppService = useAppServices()
    const [loader, setloader] = useState(true)
    const [languages, setLanguages] = useState([]);
    const { company_id } = useParams()

    const getDocsLanguages = async () => {
        const { response } = await AppService.docs.getlanguages();
        console.log(response, "responseresponse");
        if (response) {
            setLanguages(response.data);
            setloader(false);
        } else {
            setloader(false);
        }
    };
    useEffect(() => {
        getDocsLanguages();
    }, []);
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;

            setPrevScrollPos(currentScrollPos);
            setVisible(visible);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        // Check if dark mode is preferred or previously set
        const prefersDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
        const selectedNightTheme = localStorage.getItem("body_dark");

        if (selectedNightTheme === "true" || (selectedNightTheme === null && prefersDark)) {
            setIsDarkMode(true);
            applyNight();
        } else {
            setIsDarkMode(false);
            applyDay();
        }
    }, []);

    const applyNight = () => {
        if (document.querySelector('.js-darkmode-btn .ball')) {
            document.querySelector('.js-darkmode-btn .ball').style.left = "26px";
        }
        document.body.classList.add("body_dark");
    };

    const applyDay = () => {
        if (document.querySelector('.js-darkmode-btn .ball')) {
            document.querySelector('.js-darkmode-btn .ball').style.left = "3px";
        }
        document.body.classList.remove("body_dark");
    };

    const toggleDarkMode = () => {
        if (!isDarkMode) {
            setIsDarkMode(true);
            applyNight();
            localStorage.setItem("body_dark", "true");
        } else {
            setIsDarkMode(false);
            applyDay();
            localStorage.setItem("body_dark", "false");
        }
    };

    return (
        <nav className={`navbar navbar-expand-lg menu_one menu_purple sticky-nav ${visible && prevScrollPos > 0 ? 'navbar_fixed' : ''} animate__animated ${visible ? 'gap animate__fadeInDown' : 'gap animate__fadeInUp'}`} style={{ top: visible ? '0' : '-117px' }}>
            <div className="container">

                <Link className="navbar-brand header_logo" to="/">
                    <div className="logo">
                        <img style={{ display: "block" }} width={70} src={agency?.profile?.profile_image} alt="Logo" />
                    </div>
                </Link>


                <buthrefn className="navbar-hrefggler collapsed" type="buthrefn" data-hrefggle="collapse"
                    data-target="/navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                    aria-label="hrefggle navigation">
                    <span className="menu_hrefggle">
                        <span className="hamburger">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                        <span className="hamburger-cross">
                            <span></span>
                            <span></span>
                        </span>
                    </span>
                </buthrefn>

                <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ justifyContent: "center" }}>
                    <ul className="navbar-nav menu ml-auhref">
                        {/* <li className="nav-item">
                            <Link to="/" className="nav-link dropdown-hrefggle">Home</Link>


                        </li>
                        <li className="nav-item">
                            <Link to="/forumroot" className="nav-link dropdown-hrefggle">ForumRoot</Link>

                        </li> */}
                        <li className="nav-item">
                            <Link to={`/docs`} className="nav-link dropdown-hrefggle">Docs</Link>

                        </li>
                        <li className="nav-item">
                            <Link to={`/faqs`} className="nav-link dropdown-hrefggle">Faqs</Link>

                        </li>
                        {/* <li className="nav-item">
                            <Link to="/contact" className="nav-link dropdown-hrefggle">Contact Us</Link>

                        </li>
                        <li className="nav-item">
                            <Link to="/signin" className="nav-link dropdown-hrefggle">Sign Up</Link>

                        </li> */}

                    </ul>
                    <div className="right-nav">
                        <div className="px-2 js-darkmode-btn" title="hrefggle dark mode">
                            <label htmlFor="something" className="tab-btn tab-btns">
                                <ion-icon name="moon"></ion-icon>
                            </label>
                            <label htmlFor="something" className="tab-btn">
                                <ion-icon name="sunny"></ion-icon>
                            </label>
                            <label className="ball" htmlFor="something"></label>
                            <input type="checkbox" name="something" id="something" className="dark_mode_switcher" onChange={toggleDarkMode} />
                        </div>
                    </div>
                </div>
                {
                    languages &&
                    <div>
                        <select name="" className='form-control' style={{ width: "10rem" }} onChange={handleLanguage}>
                            <option value="english"> English</option>
                            {
                                languages.map((language) =>
                                    <option key={language._id} value={language._id}>{language.name}</option>

                                )
                            }
                        </select>
                    </div>
                }
            </div>
        </nav>
    )
}

export default Navbar
Navbar.propTypes = {
    agency: PropTypes.object.isRequired,
    handleLanguage: PropTypes.func.isRequired,
};