import React, { useEffect, useState } from "react";
import { useAppServices } from "../../../hook/services";
import Loader from "../../../components/Loader";
import Footer from "../../../components/Footer";
import { useParams } from "react-router-dom";

const Faqs = () => {
  const AppService = useAppServices()
  const { company_id } = useParams()
  const [data, setData] = useState([])
  const [profile, setprofile] = useState({});
  const [loader, setloader] = useState(true)
  const [activeQuestion, setactiveQuestion] = useState('')
  const getDocsData = async () => {
    setloader(true)
    // const domain = window.location.hostname
    const domain = 'test.levelupmarketplace.io'
    const { response } = await AppService.k_faq_category.get({
      query: `domain=${domain}`
    });
    console.log(response, 'responseresponse')
    if (response) {
      setData(response)
      setprofile(response.profile)
      setloader(false)
    } else {
      setloader(false)
    }
  }
  const onLoad = () => {
    getDocsData()
  }
  useEffect(() => {
    onLoad()
  }, []);

  return (
    <>
      {
        loader ?
          <Loader />
          :
          <>
            <div className="shortcode_title">
              <h1 className="shortcode_title_faq">FAQs</h1>
            </div>
            <div className="row ">
              {
                data?.data?.map((category) =>

                  <div className="col-lg-6  col-md-8  doc-middle-content " key={category._id}>
                    <div id="post" className="shortcode_info">
                      <div className="shortcode_title" style={{ textAlign: "center" }}>
                        <h4>{category.name}</h4>
                      </div>
                      <div className="toggle_shortcode">
                        {
                          data?.faqs.filter((item) => item.category?._id == category._id)?.map((faq) =>
                            <>
                              <a
                                onClick={() => setactiveQuestion(activeQuestion == faq._id ? "" : faq._id)}
                                className={`toggle_btn  ${faq._id == activeQuestion ? "" : "collapsed"}`}
                                data-toggle="collapse"
                                role="button"
                                aria-expanded="false"
                                aria-controls={faq._id}
                              >
                                {faq.question}
                              </a>
                              <div
                                className={`collapse multi-collapse ${faq._id == activeQuestion ? "show" : ""}`}
                                id={faq._id}
                              >
                                <div className="card card-body toggle_body">
                                  {faq.answer}
                                </div>
                              </div>

                            </>
                          )}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
            <Footer data={profile} />
          </>
      }
    </>
  );
};

export default Faqs;
