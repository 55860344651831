import React, { useEffect, useState } from "react";
import Docs from "./components/Docs";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Loader from "../../components/Loader";
import { useAppServices } from "../../hook/services";



const index = ({ agency }) => {
  const [seleted_language, setseleted_language] = useState('english')


  return (
    <div className="body_wrapper">
      <Navbar agency={agency} handleLanguage={(e) => setseleted_language(e.target.value)} />
      <Banner />
      <Docs seleted_language={seleted_language} />
    </div>
  );
};

export default index;
